import classNames from 'classnames'
import React from 'react'
import ReactBalanceText from 'react-balance-text'
import type { Component } from 'constants/types'
import styles from './Heading.module.scss'

type Props = {
  align?: 'left' | 'center'
  children: React.ReactNode
  element?: 'legend'
  flush?: boolean
  level?: 1 | 2 | 3 | 4
  levelStyle?: 1 | 2 | 3 | 4
} & Component

export default function Heading({
  align,
  children,
  className,
  element,
  flush,
  level = 2,
  levelStyle,
}: Props) {
  let Component = 'h2'

  if (level === 1) {
    Component = 'h1'
  } else if (level === 3) {
    Component = 'h3'
  } else if (level === 4) {
    Component = 'h4'
  }

  if (element === 'legend') {
    Component = 'legend'
  }

  let body = children

  if ((level === 1 || level === 2) && align !== 'left') {
    body = <ReactBalanceText>{children}</ReactBalanceText>
  }

  return (
    <Component
      className={classNames(
        'Heading',
        styles.this,
        align && styles[`this---${align}`],
        flush && styles['this---flush'],
        !levelStyle && level && styles[`this---${level}`],
        levelStyle && styles[`this---${levelStyle}`],
        className
      )}>
      {body}
    </Component>
  )
}
